// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-compte-js": () => import("./../../../src/pages/compte.js" /* webpackChunkName: "component---src-pages-compte-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/SimplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

